<!--
 * @Author: your name
 * @Date: 2020-11-06 15:57:57
 * @LastEditTime: 2020-11-16 14:55:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\productionManagement\logisticsMonitoring\projectAddressWindow.vue
-->
<template>
    <div class="projectAddressWindow">
        <!-- <div class="icon iconfont iconshangjiantou1"></div> -->
        <ul>
            <li>
                <span class="iconfont iconorder"></span>
                <span>任务单号：</span>
                <span>
                    <el-select v-model="selectProject" @change="changeTask" placeholder="请选择">
                        <el-option
                            v-for="item in projectList"
                            :key="item.task_number"
                            :label="item.task_number"
                            :value="item.task_number"
                        >
                        </el-option>
                    </el-select>
                </span>
            </li>
            <li class="columns">
                <div>
                    <span class="iconfont iconnumber1"></span>
                    <span>标号：{{ taskInfo.strength_grade_name }}</span>
                </div>
                <div>
                    <span class="iconfont iconjiaobanzhan"></span>
                    <span>供应场站：{{ taskInfo.station_name }}</span>
                </div>
            </li>
            <li class="columns">
                <div>
                    <span class="iconfont icongongdi"></span>
                    <span>工程名称：{{ taskInfo.project_name }}</span>
                </div>
            </li>
            <li class="columns">
                <div>
                    <span class="iconfont iconqiye"></span>
                    <span>客户名称：{{ taskInfo.customer_name }}</span>
                </div>
            </li>
            <li class="columns">
                <div>
                    <span class="iconfont iconyewuyuan"></span>
                    <span>联系人：{{ taskInfo.xclxr }}</span>
                </div>
                <div>
                    <span class="iconfont icondianhua1"></span>
                    <span>联系电话：{{ taskInfo.xclxr_phone }}</span>
                </div>
            </li>
            <li class="columns">
                <div>
                    <span class="iconfont iconcube" style="font-size: .18rem;"></span>
                    <span>任务方量：{{ taskInfo.quantity }}方</span>
                </div>
                <div>
                    <span>已发方量：{{ taskInfo.grand_total_quantity }}方</span>
                </div>
                <div>
                    <span>剩余方量：{{ taskInfo.surplus_quantity }}方</span>
                </div>
            </li>
            <li class="columns">
                <div>
                    <span class="iconfont iconjiaobanche"></span>
                    <span class="bg-span">运输中：{{ taskInfo.carrage_count }}车</span>
                </div>
                <div>
                    <span class="color-green">已到达：{{ taskInfo.arrive_count }}车</span>
                </div>
                <div>
                    <span class="color-purple">卸料完成：{{ taskInfo.unload_count }}车</span>
                </div>
            </li>
            <li class="columns">
                <div>
                    <span class="iconfont iconshijian1"></span>
                    <span>到货时间：{{ taskInfo.arrival_time }}</span>
                </div>
            </li>
            <li class="columns">
                <div>
                    <span class="iconfont iconshijian1"></span>
                    <span>上一车发货时间：{{ taskInfo.pre_send_time }}</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            projectList: [],
            selectProject: '',
            taskInfo: {},
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {},
    methods: {
        // 处理工地关联任务单
        async projectAddressInfo(marker) {
            const res = await this.getInfo(marker.project_code);
            if (res) {
                this.projectList = res;
                this.taskInfo = res[0];
                this.selectProject = res[0].task_number;
                const _parent = this.$parent.$parent.$parent;
                _parent.addressWindow.visible = false;
                _parent.$nextTick(() => {
                    _parent.addressWindow.visible = true;
                    _parent.addressWindow.position = marker.position;
                });
            }
        },
        // 切换任务单
        changeTask(task_number) {
            const arr = this.projectList.filter(item => item.task_number === task_number);
            if (arr.length > 0) {
                this.taskInfo = arr[0];
            }
        },
        // 获取工地关联任务单列表
        getInfo(project_code) {
            const path = '/interfaceApi/production/mapmanager/get_task_list?project_code=' + project_code;
            return this.$axios.$get(path, { defEx: true });
        },
    },
};
</script>
<style lang="stylus" scoped>
.projectAddressWindow
    background #fff
    width 5.5rem
    .icon
        position absolute
        bottom 3px
        left 50%
        -webkit-transform translateX(-50%)
        transform translateX(-50%)
        font-size 0.24rem
        color #fff
    ul
        li
            height .48rem
            line-height .48rem
            border-bottom 1px solid #F0F0F0
            font-size .16rem
            padding-left .2rem
            color #666
            >div
                >span
                    vertical-align middle
            .iconfont
                font-size .16rem
                margin-right .05rem
                vertical-align middle
            &:nth-child(1)
                font-size .18rem
                color #333
                .iconfont
                    font-size .18rem
            &.columns
                display flex
                >div
                    flex 1
                    .bg-span
                        padding 0 .1rem
                        height .3rem
                        line-height .3rem
                        display inline-block
                        background #0048B8
                        border-radius 2px
                        color #fff
                    .color-green
                        color #20AD52
                    .color-purple
                        color #9152D8

</style>